import { initializeApp } from 'firebase/app' // no compat for new SDK
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: 'rb-gsp-app.firebaseapp.com',
  projectId: "rb-gsp-app",
  databaseURL: 'https://rb-gsp-app.firebaseio.com',
  storageBucket: 'rb-gsp-app.appspot.com',
  messagingSenderId: '29861248854',
  appId: '1:29861248854:web:cd9ac290b5b5ed9493e0f7',
  measurementId: "G-V34R9BNMYK"
}

const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
