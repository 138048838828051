import React, {useEffect, useState} from 'react';
import logo from './logo.png';
import logo512 from './logo512.png'
import './App.css';
import axios from "axios";
import {loadScriptFromText} from "./lib/load-script";
import {HashLoader} from "react-spinners";
import {firestore} from "./data/firebase";
import { doc, getDoc } from 'firebase/firestore'
import {ContactInfo} from "./data/contact-info";
import {useReactPWAInstall} from "react-pwa-install-updated";
import { PwaUpdater } from 'pwa-updater';
import {countryContinent} from "./data/continent";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [detailHtml, setDetailHtml] = useState('');
  const [appText, setAppText] = useState('');
  const [addToHomeScreenButtonText, setAddToHomeScreenButtonText] = useState('');
  const [addToHomeScreenTitle, setAddToHomeScreenTitle] = useState('');
  const [addToHomeScreenDescription, setAddToHomeScreenDescription] = useState('');
  const [chatIntroduceText, setChatIntroduceText] = useState('');
  const [chatDivId, setChatDivId] = useState('');
  const [pwaDescriptionTitle, setPwaDescriptionTitle] = useState('');
  const [pwaInstallTitle, setPwaInstallTitle] = useState('');
  const [pwaInstallActionOk, setPwaInstallActionOk] = useState('');
  const [pwaInstallActionCancel, setPwaInstallActionCancel] = useState('');
  const [pwaInstallActionInstall, setPwaInstallActionInstall] = useState('');
  const [pwaInstallIdeviceAction1, setPwaInstallIdeviceAction1] = useState('');
  const [pwaInstallIdeviceAction2, setPwaInstallIdeviceAction2] = useState('');
  const [pwaInstallFirefoxAction1, setPwaInstallFirefoxAction1] = useState('');
  const [pwaInstallFirefoxAction2, setPwaInstallFirefoxAction2] = useState('');
  const [pwaInstallFirefoxNewAction1, setPwaInstallFirefoxNewAction1] = useState('');
  const [pwaInstallFirefoxNewAction2, setPwaInstallFirefoxNewAction2] = useState('');
  const [pwaInstallOperaAction1, setPwaInstallOperaAction1] = useState('');
  const [pwaInstallOperaAction2, setPwaInstallOperaAction2] = useState('');
  const [pwaInstallNotSupported, setPwaInstallNotSupported] = useState('');
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const addToHomeScreen = () => {
    pwaInstall({
      title: addToHomeScreenTitle,
      description: addToHomeScreenDescription,
      descritpionTitle: pwaDescriptionTitle,
      instructionTitle: pwaInstallTitle,
      instructionActionOk: pwaInstallActionOk,
      instructionActionCancel: pwaInstallActionCancel,
      instructionActionInstall: pwaInstallActionInstall,
      instructionIdeviceAction1: pwaInstallIdeviceAction1,
      instructionIdeviceAction2: pwaInstallIdeviceAction2,
      instructionFirefoxAction1: pwaInstallFirefoxAction1,
      instructionFirefoxAction2: pwaInstallFirefoxAction2,
      instructionFirefoxNewAction1: pwaInstallFirefoxNewAction1,
      instructionFirefoxNewAction2: pwaInstallFirefoxNewAction2,
      instructionOperaAction1: pwaInstallOperaAction1,
      instructionOperaAction2: pwaInstallOperaAction2,
      instructionNotSupported: pwaInstallNotSupported,
    }).then(() => {
      console.log('App installed successfully or the install instruction was shown.')
    });
  }

  useEffect( () => {
    const getData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      let country = queryParams.get('country')?.toUpperCase();
      if (!country) {
        setIsLoading(true);
        const res = await axios.get('https://us-central1-dev-rain-bird-connect.cloudfunctions.net/app/get-ip-details');
        setIsLoading(false);
        const geoData = res.data as { countryCode: string }
        country = geoData.countryCode;
      }

      if (!country) {
        country = 'US';
      }
      setIsLoading(true);
      const globalRef = doc(firestore, 'countries', 'global');
      const americaRef = doc(firestore, 'countries', 'america');
      const countryRef = doc(firestore, 'countries', country.toLowerCase());
      const countrySnap = await getDoc(countryRef);
      let countryData;
      if (countrySnap.exists()) {
        countryData = countrySnap.data() as ContactInfo;
      } else {
        // If country is in America continent, then display america continent data
        if (countryContinent[country.toUpperCase()] === 'SA' || countryContinent[country.toUpperCase()] === 'NA') {
          countryData = (await getDoc(americaRef)).data() as ContactInfo;
        } else {
          countryData = (await getDoc(globalRef)).data() as ContactInfo;
        }
      }
      if (!countryData) {
        return;
      }
      setChatDivId(countryData.divId);
      loadScriptFromText(countryData.script);
      setDetailHtml(countryData.detailHtml);
      setCountry(countryData.country);
      setAppText(countryData.appText);
      setAddToHomeScreenButtonText(countryData.addToHomeScreenButtonText);
      setChatIntroduceText(countryData.chatIntroduceText);
      setAddToHomeScreenTitle(countryData.addToHomeScreenTitle);
      setAddToHomeScreenDescription(countryData.addToHomeScreenDescription);
      setPwaDescriptionTitle(countryData.pwaDescriptionTitle);
      setPwaInstallTitle(countryData.pwaInstallTitle);
      setPwaInstallActionOk(countryData.pwaInstallActionOk);
      setPwaInstallActionCancel(countryData.pwaInstallActionCancel);
      setPwaInstallActionInstall(countryData.pwaInstallActionInstall);
      setPwaInstallIdeviceAction1(countryData.pwaInstallIdeviceAction1);
      setPwaInstallIdeviceAction2(countryData.pwaInstallIdeviceAction2);
      setPwaInstallFirefoxAction1(countryData.pwaInstallFirefoxAction1);
      setPwaInstallFirefoxAction2(countryData.pwaInstallFirefoxAction2);
      setPwaInstallFirefoxNewAction1(countryData.pwaInstallFirefoxNewAction1);
      setPwaInstallFirefoxNewAction2(countryData.pwaInstallFirefoxNewAction2);
      setPwaInstallOperaAction1(countryData.pwaInstallOperaAction1);
      setPwaInstallOperaAction2(countryData.pwaInstallOperaAction2);
      setPwaInstallNotSupported(countryData.pwaInstallNotSupported);
      setIsLoading(false);
    }

    getData();
  }, [])

  return (
    <div className="App">
      <PwaUpdater
        notify={false}
      />
      { isLoading && (
        <div className="absolute w-full h-full flex items-center justify-center bg-white opacity-80" >
          <HashLoader color="#00843D" size="120px"></HashLoader>
        </div>
      )}

      <header className="bg-appGreen flex justify-center h-24">
        <img src={logo} className="app-logo h-20" alt="logo"/>
      </header>
      <section className="flex flex-col justify-center">
        <div className="flex justify-center">
          <img src={logo512} className="h-32" alt="Logo with text"/>
        </div>
        <div className="flex flex-col items-center">
          <span className="font-bold mb-1">{appText}</span>
          <span>{ country }</span>
        </div>
        <div dangerouslySetInnerHTML={{__html: detailHtml}}></div>
        <div className="flex flex-col items-center mt-5">
          { supported() && !isInstalled() && isMobile() && addToHomeScreenButtonText && (
            <button
              type="button"
              className="bg-appGreen inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
              onClick={addToHomeScreen}
            >
              {addToHomeScreenButtonText}
            </button>
          )}
          <span className="text-center mt-3">{chatIntroduceText}</span>
        </div>
      </section>
      <div id={chatDivId}></div>
    </div>
  );
}

export default App;
